<template>
  <div class="about">
    <b-container class="py-3">
      <page-title title="Profilom">
        <template #action>
          <b-button variant="link" @click="logout"
            ><span class="d-none d-sm-inline mr-2">Kijelentkezés</span>
            <b-icon icon="box-arrow-right"></b-icon>
          </b-button>
        </template>
      </page-title>
      <b-card no-body>
        <b-tabs card pills>
          <b-tab title="Adatok" active>
            <profile-editor
              v-if="currentUser"
              :user="currentUser"
            ></profile-editor>
            <div v-else class="d-flex justify-content-center my-5">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
            </div>
          </b-tab>
          <b-tab title="Javaslataim">
            <p v-if="!myGames.length" class="text-center text-primary">
              Még nem küldtél be javaslatot. Ha hozzáadsz egy új játékot, vagy
              módosítasz valamit, az itt fog megjelenni.
            </p>
            <game-requests
              v-for="game in myGames"
              :key="game.id"
              :game="game"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import AuthMixin from "@/mixins/AuthMixin";
import UserModuleMixin from "@/mixins/UserModuleMixin";
import ProfileEditor from "../components/ProfileEditor.vue";
import PageTitle from "../components/PageTitle.vue";
import GameRequests from "../components/GameRequests.vue";
import GameModuleMixin from "../mixins/GameModuleMixin";
export default {
  name: "Profile",
  components: { ProfileEditor, PageTitle, GameRequests },
  mixins: [AuthMixin, UserModuleMixin, GameModuleMixin],
  data: () => ({ requiredRole: "user" }),
  computed: {
    myGames() {
      if (!this.userModuleLoaded) return [];
      return this.gameModuleItems.filter((g) =>
        g.changeRequests.find((cr) => cr.authorId === this.currentUser.uid)
      );
    }
  }
};
</script>
